<template>
    <div class="follow-detail">
        <el-dialog
            center
            width="30%"
            title="跟进记录"
            :visible.sync="isShowDialog"
            :before-close="handleClose"
        >
            <div class="timeblock">
                <el-timeline>
                    <el-timeline-item
                        v-for="(item, index) in info.followLogList"
                        :key="index"
                        type="primary"
                    >
                        <time>{{ item.followedTime | getYMDHMS }}</time>
                        <span>({{ item.follower }})</span>
                        <h4 style="border-left: 6px solid #4086EC">
                            <span />
                            <p style="color: #4086ec">{{ item.modifyContent }}</p>
                            <p>{{ item.followContent }}</p>
                        </h4>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShowDialog: false,
            info: {},
        };
    },
    methods: {
        // 展示根据信息时间的弹框
        showDetail(info) {
            console.log(info);
            this.info = info;
            this.isShowDialog = true;
        },
        // 弹框关闭触发
        handleClose() {
            this.isShowDialog = false;
        },
    },
};
</script>

<style lang="less" scoped>
@import '../../css/table.less';
.follow-detail {
    /deep/ .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 398px;
        transform: translate(-50%, -50%);
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
        .el-dialog__body {
            padding: 30px 25px;

            flex: 1;
            overflow: auto;
        }
    }
}
</style>